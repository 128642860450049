/* Dropdown Container */
.tekup-select {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

/* Select Element */
.tekup-select select {
  width: 100%;
  padding: 12px 15px;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  appearance: none;
  transition: all 0.3s ease;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

/* Focus State */
.tekup-select select:focus {
  border-color: #4e6bff;
  box-shadow: 0 0 0 2px rgba(78, 107, 255, 0.2);
}

/* Dropdown Arrow */
.tekup-select i {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #666;
  transition: all 0.3s ease;
}

/* Hover State */
.tekup-select:hover select {
  border-color: #ccc;
}

.tekup-select:hover i {
  color: #333;
}

/* Disabled State */
.tekup-select select:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

/* Options Styling */
.tekup-select select option {
  padding: 8px 12px;
  color: #333;
}

.tekup-select select option:checked {
  background-color: #f0f4ff;
  color: #4e6bff;
}

/* Dark Mode Support (optional) */
@media (prefers-color-scheme: dark) {
  .tekup-select select {
    background-color: #2d3748;
    color: #f7fafc;
    border-color: #4a5568;
  }
  
  .tekup-select i {
    color: #a0aec0;
  }
  
  .tekup-select select option {
    background-color: #2d3748;
    color: #f7fafc;
  }
  
  .tekup-select select:focus {
    border-color: #667eea;
    box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.2);
  }
}