/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



/*----------------------------------------*/
/*  4.9 Courses css
/*----------------------------------------*/
.bd-course-thumb-style {
  display: block;
  overflow: hidden;
  font-family: "Big Shoulders Display", sans-serif;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-course-thumb-style {
    height: 300px;
  }
}
.bd-course-thumb-style .bd-course-thumb-bg {
  border-radius: 16px 16px 0 0;
  padding: 15px 15px;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-1 {
  background-color: #D5EFDE;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-2 {
  background-color: #EFCBEF;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-3 {
  background-color: #F2E2CA;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-4 {
  background-color: #BBDBFB;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-5 {
  background-color: #E7DDDE;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-6 {
  background-color: #EED8E2;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-7 {
  background-color: #DBEACE;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-8 {
  background-color: #E0C7D1;
}
.bd-course-thumb-style .bd-course-thumb-bg.bg-9 {
  background-color: #FBDEBD;
}
.bd-course-thumb-style .bd-course-thumb-bg img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.bd-course-thumb-style .bd-course-thumb-instructor {
  position: absolute;
  bottom: 0;
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-course-thumb-style .bd-course-thumb-instructor {
    width: 160px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-thumb-style .bd-course-thumb-instructor {
    width: 120px;
  }
}
@media (max-width: 575px) {
  .bd-course-thumb-style .bd-course-thumb-instructor {
    width: 140px;
  }
}
.bd-course-thumb-style .bd-course-thumb-instructor.center {
  left: 50%;
  transform: translateX(-50%);
}
.bd-course-thumb-style .bd-course-thumb-instructor.right {
  right: 20px;
}
.bd-course-thumb-style .bd-course-thumb-instructor img {
  width: 100%;
  height: 100%;
}
.bd-course-thumb-style .bd-course-overly-title {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: var(--bd-ff-style);
  font-weight: var(--bd-fw-bold);
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-thumb-style .bd-course-overly-title.fs-200 {
    font-size: 160px;
  }
}
@media (max-width: 575px) {
  .bd-course-thumb-style .bd-course-overly-title.fs-200 {
    font-size: 170px;
  }
}
@media (max-width:450px) {
  .bd-course-thumb-style .bd-course-overly-title.fs-200 {
    font-size: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-thumb-style .bd-course-overly-title.fs-140 {
    font-size: 120px;
  }
}
@media (max-width: 575px) {
  .bd-course-thumb-style .bd-course-overly-title.fs-140 {
    font-size: 110px;
  }
}
.bd-course-thumb-style .small-text {
  position: absolute;
  top: 36px;
  left: 30px;
  font-size: 27px;
  font-family: var(--bd-ff-style);
  font-weight: var(--bd-fw-sbold);
}
.bd-course-thumb-style .bd-course-text-content {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.bd-course-thumb-style .bd-course-text-content .text, .bd-course-thumb-style .bd-course-text-content .text-1, .bd-course-thumb-style .bd-course-text-content .text-2, .bd-course-thumb-style .bd-course-text-content .text-3 {
  font-family: var(--bd-ff-style);
  font-weight: var(--bd-fw-bold);
  line-height: 1;
}
.bd-course-thumb-style.small-style .bd-course-overly-title {
  font-size: 140px;
}
.bd-course-thumb-style.small-style .bd-course-overly-title.fs-140 {
  font-size: 100px;
}
.bd-course-thumb-style.small-style .small-text {
  font-size: 20px;
  top: 27px;
}
.bd-course-thumb-style.small-style .bd-course-text-content .text-1 {
  font-size: 36px;
}
.bd-course-thumb-style.small-style .bd-course-text-content .text-2 {
  font-size: 30px;
}
.bd-course-thumb-style.small-style .bd-course-text-content .text-3 {
  font-size: 20px;
}
.bd-course-thumb-style.small-style .bd-course-thumb-instructor {
  width: 120px;
}

.bd-course-thumb-style-two {
  overflow: hidden;
  display: block;
}
.bd-course-thumb-style-two:hover .bd-course-thumb img {
  transform: scale(1.1);
}
.bd-course-thumb-style-two .bd-course-thumb {
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}
.bd-course-thumb-style-two .bd-course-thumb img {
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  transition: all 0.3s ease-in-out;
}
.bd-course-thumb-style-two .bd-course-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bd-course-thumb-style-two .bd-course-shape img {
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
}
.bd-course-thumb-style-two .bd-course-logo {
  position: absolute;
  z-index: 999;
  bottom: 20px;
  right: 90px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-course-thumb-style-two .bd-course-logo {
    bottom: 15px;
    right: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-thumb-style-two .bd-course-logo {
    bottom: 10px;
    right: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-course-thumb-style-two .bd-course-logo {
    right: 40px;
    width: 72px;
    bottom: 5px;
  }
}
@media (max-width: 575px) {
  .bd-course-thumb-style-two .bd-course-logo {
    right: 110px;
  }
}
@media (max-width:450px) {
  .bd-course-thumb-style-two .bd-course-logo {
    bottom: 15px;
    right: 70px;
  }
}

.bd-course-thumb-style-three {
  border-radius: 16px 16px 0 0;
  display: block;
}
.bd-course-thumb-style-three .bd-course-thumb-bg {
  border-radius: 16px 16px 0 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-course-thumb-style-three .bd-course-thumb-bg {
    height: 300px;
  }
}
.bd-course-thumb-style-three .bd-course-thumb-bg img {
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 100%;
}
.bd-course-thumb-style-three .bd-course-instructor {
  position: absolute;
  right: 10px;
  bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-thumb-style-three .bd-course-instructor {
    width: 120px;
  }
}
.bd-course-thumb-style-three .bd-course-instructor img {
  filter: drop-shadow(-4px 0px var(--bd-white));
  width: 100%;
  height: 100%;
}
.bd-course-thumb-style-three .bd-course-logo {
  position: absolute;
  bottom: 25px;
  left: 30px;
}
.bd-course-thumb-style-three .bd-course-logo img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid var(--bd-white);
  border-radius: 6px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-thumb-style-three .bd-course-logo img {
    width: 50px;
    height: 50px;
  }
}
.bd-course-thumb-style-three .bd-course-text-content {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 50px;
}
.bd-course-thumb-style-three .bd-course-text-content .text, .bd-course-thumb-style-three .bd-course-text-content .text-1, .bd-course-thumb-style-three .bd-course-text-content .text-2, .bd-course-thumb-style-three .bd-course-text-content .text-3 {
  font-family: var(--bd-ff-style);
  font-weight: var(--bd-fw-bold);
  line-height: 1;
}

.bd-course-wrapper.style-one .bd-course-tag span a {
  font-size: 16px;
  font-weight: var(--bd-fw-regular);
  text-transform: capitalize;
  padding: 7px 24px 7px 24px;
  border-radius: 0px 8px 0 0;
  color: var(--bd-taxonomy-color);
  background-color: var(--bd-white);
  position: absolute;
  left: 0;
  bottom: -1px;
  transition: var(--transition);
  z-index: 9;
}
.bd-course-wrapper.style-one .bd-course-tag span a:hover {
  color: var(--bd-white);
  background-color: var(--bd-secondary);
}
.bd-course-wrapper.style-one .bd-course-thumb {
  border-radius: 10px 10px 0 0;
}
.bd-course-wrapper.style-one .bd-course-thumb img {
  width: 100%;
  height: 100%;
}
.bd-course-wrapper.style-one .bd-course-content {
  padding: 22px 22px 30px 22px;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--bd-border-primary);
  background-color: var(--bd-bg-primary);
}
.bd-course-wrapper.style-one:hover .bd-course-tag span a {
  color: var(--bd-white);
  background-color: var(--bd-primary);
}
.bd-course-wrapper.style-one:hover .bd-course-thumb img {
  transform: scale(1.1);
}
.bd-course-wrapper.style-two .bd-course-content {
  padding: 5px 15px 20px 15px;
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--bd-border-primary);
  background-color: var(--bd-bg-primary);
}
.bd-course-wrapper.style-two .bd-course-meta {
  margin-bottom: 15px;
}
.bd-course-wrapper.style-two .bd-course-title {
  margin-bottom: 15px;
}
.bd-course-wrapper.style-three {
  position: relative;
}
.bd-course-wrapper.style-three .bd-course-content {
  padding: 22px 22px 22px 22px;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset-inline-start: 0;
  bottom: -1px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 9;
  border-radius: 0 0 10px 10px;
}
.bd-course-wrapper.style-three .bd-course-rating-text span {
  color: var(--bd-light);
}
.bd-course-wrapper.style-three .bd-course-title {
  color: var(--bd-white);
}
.bd-course-wrapper.style-three .bd-course-price .current-price {
  color: var(--bd-white);
}
.bd-course-wrapper.style-three .bd-course-price .old-price {
  color: var(--bd-light);
}
.bd-course-wrapper.style-three .bd-course-author .name {
  color: var(--bd-white);
}
.bd-course-wrapper.style-three .bd-course-author .name:hover {
  color: var(--bd-primary);
}
.bd-course-wrapper.style-three .bd-course-lesson span {
  color: var(--bd-light);
}
.bd-course-wrapper.style-three .bd-course-thumb {
  height: 450px;
  width: 100%;
  border-radius: 10px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-three .bd-course-thumb {
    height: 450px;
  }
}
.bd-course-wrapper.style-three .bd-course-thumb img {
  width: 100%;
  min-height: 100%;
  border-radius: 10px;
}
.bd-course-wrapper.style-three .bd-course-top-meta .bd-course-badge {
  position: absolute;
  top: 16px;
  left: 16px;
  right: auto;
}
.bd-course-wrapper.style-three .bd-course-top-meta .bd-course-like {
  position: absolute;
  inset-inline-end: 16px;
  top: 16px;
}
.bd-course-wrapper.style-three:hover {
  filter: drop-shadow(0px 4px 70px rgba(0, 0, 0, 0.15));
}
.bd-course-wrapper.style-three:hover .bd-course-content {
  background-color: var(--bd-white);
}
.bd-course-wrapper.style-three:hover .bd-course-content::before {
  background-image: none;
}
.bd-course-wrapper.style-three:hover .bd-course-rating-text span {
  color: rgba(0, 0, 0, 0.6);
}
.bd-course-wrapper.style-three:hover .bd-course-title {
  color: var(--bd-theme-black);
}
.bd-course-wrapper.style-three:hover .bd-course-price .current-price {
  color: var(--bd-theme-black);
}
.bd-course-wrapper.style-three:hover .bd-course-price .old-price {
  color: rgba(0, 0, 0, 0.6);
}
.bd-course-wrapper.style-three:hover .bd-course-author .name {
  color: var(--bd-theme-black);
}
.bd-course-wrapper.style-three:hover .bd-course-lesson span {
  color: rgba(0, 0, 0, 0.6);
}
.bd-course-wrapper.style-three:hover .bd-course-thumb img {
  transform: scale(1.1);
}
.bd-course-wrapper.style-four {
  background: var(--bd-white);
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
}
.bd-course-wrapper.style-four:hover .bd-course-thumb img {
  transform: scale(1.1);
}
.bd-course-wrapper.style-four .bd-course-thumb-style-three {
  display: block;
  border-radius: 16px;
  margin-bottom: 20px;
  position: relative;
}
.bd-course-wrapper.style-four .bd-course-thumb-style-three .bd-course-thumb-bg {
  border-radius: 16px;
}
.bd-course-wrapper.style-four .bd-course-thumb-style-three .bd-course-thumb-bg img {
  border-radius: 16px;
}
.bd-course-wrapper.style-four .bd-course-thumb {
  overflow: hidden;
  transition: all 0.3s ease-out 0s;
  border-radius: 24px;
  position: relative;
  width: 100%;
}
.bd-course-wrapper.style-four .bd-course-thumb img {
  width: 100%;
  transition: all 0.3s ease-out 0s;
  height: 100%;
  object-fit: cover;
}
.bd-course-wrapper.style-four .shape {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}
.bd-course-wrapper.style-four .shape img {
  width: 100%;
}
.bd-course-wrapper.style-four .bd-course-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bd-course-wrapper.style-four .bd-course-info-item-wrapper {
  padding: 16px 20px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}
.bd-course-wrapper.style-four .bd-course-info-item {
  position: relative;
  text-align: center;
}
.bd-course-wrapper.style-four .bd-course-info-item:not(:last-child)::before {
  content: "";
  width: 2px;
  height: 45px;
  position: absolute;
  inset-inline-end: -50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  border-right: 2px dashed var(--bd-white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-course-wrapper.style-four .bd-course-info-item:not(:last-child)::before {
    inset-inline-end: -70%;
  }
}
.bd-course-wrapper.style-four .bd-course-info-item-title {
  font-size: 18px;
  line-height: 23px;
  color: var(--bd-white);
  margin-bottom: 0;
}
.bd-course-wrapper.style-four .bd-course-info-item-title span {
  font-size: 16px;
  font-weight: var(--bd-fw-normal);
}
.bd-course-wrapper.style-five {
  box-shadow: 0px 20px 40px rgba(24, 44, 74, 0.05);
  border-radius: 10px;
  position: relative;
}
.bd-course-wrapper.style-five:hover .bd-course-info {
  opacity: 1;
  visibility: visible;
}
.bd-course-wrapper.style-five .bd-course-thumb {
  border-radius: 10px;
  overflow: hidden;
}
.bd-course-wrapper.style-five .bd-course-content {
  padding: 22px 22px 30px 22px;
  background: var(--bd-white);
  border-radius: 0px 0px 10px 10px;
}
.bd-course-wrapper.style-five .border-pile {
  position: relative;
}
.bd-course-wrapper.style-five .border-pile::before {
  position: absolute;
  height: 75px;
  width: 1px;
  background: var(--bd-border-primary);
  content: "";
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
}
.bd-course-wrapper.style-six:hover .bd-course-thumb img {
  transform: scale3d(1.15, 1.15, 1.15);
  overflow: hidden;
}
.bd-course-wrapper.style-six .bd-course-thumb {
  overflow: hidden;
  position: relative;
}
.bd-course-wrapper.style-six .bd-course-thumb::before {
  position: absolute;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 23, 15, 0.2);
  z-index: 4;
}
.bd-course-wrapper.style-six .bd-course-thumb img {
  border-radius: 0;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.bd-course-wrapper.style-six .bd-course-content {
  padding: 22px 22px 30px 22px;
  background-color: var(--bd-white);
  box-shadow: 0px 10px 40px rgba(28, 51, 84, 0.07);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.bd-course-wrapper.style-seven {
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(131, 131, 131, 0.1);
}
.bd-course-wrapper.style-seven:hover .bd-course-info {
  opacity: 1;
  visibility: visible;
}
.bd-course-wrapper.style-seven .bd-course-content {
  padding: 22px 22px 30px 22px;
  border-radius: 0 0 16px 16px;
  background-color: var(--bd-white);
}
.bd-course-wrapper.style-seven .bd-course-info {
  width: 100%;
  height: 500px;
  background-color: var(--bd-white);
  border-bottom: 1px solid var(--bd-border-primary);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-seven .bd-course-info {
    display: none;
  }
}
.bd-course-wrapper.style-seven .bd-course-info p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
}
.bd-course-wrapper.style-seven .bd-course-info-list ul {
  margin-bottom: 15px;
}
.bd-course-wrapper.style-seven .bd-course-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bd-course-wrapper.style-eight {
  overflow: hidden;
}
.bd-course-wrapper.style-eight .bd-course-content {
  padding: 25px 25px 25px 25px;
  border-radius: 0 0 6px 6px;
  position: relative;
}
.bd-course-wrapper.style-eight .bd-course-btn {
  position: absolute;
  right: 23px;
  top: -25px;
}
.bd-course-wrapper.style-eight:hover .bd-course-thumb img {
  transform: scale3d(1.07, 1.07, 1.07);
}
.bd-course-wrapper.style-eight .bd-course-thumb {
  border-radius: 6px 6px 0 0;
  height: 250px;
}
.bd-course-wrapper.style-eight .bd-course-thumb img {
  transition: all 1.1s cubic-bezier(0, 0, 0.2, 1);
}
.bd-course-wrapper.style-eight .bd-course-author {
  margin-bottom: 10px;
}
.bd-course-wrapper.style-eight .bd-course-title {
  margin-bottom: 15px;
}
.bd-course-wrapper.style-eight .bd-course-description {
  margin-bottom: 15px;
}
.bd-course-wrapper.style-eight .bd-course-price {
  margin-bottom: 5px;
}
.bd-course-wrapper.style-nine {
  padding: 22px 22px 30px 22px;
  border-radius: 24px;
}
.bd-course-wrapper.style-nine .bd-course-thumb {
  border-radius: 16px;
  margin-bottom: 20px;
}
.bd-course-wrapper.style-nine:hover .bd-course-thumb img {
  transform: scale(1.1);
}
.bd-course-wrapper.style-ten .bd-course-thumb {
  border-radius: 70px 0 0 0;
}
.bd-course-wrapper.style-ten .bd-course-thumb img {
  border-radius: 0;
  transition: 1.1s cubic-bezier(0, 0, 0.2, 1);
  height: 280px;
}
.bd-course-wrapper.style-ten .bd-course-thumb-style {
  overflow: hidden;
  border-radius: 70px 0 0 0;
}
.bd-course-wrapper.style-ten .bd-course-thumb-style img {
  border-radius: 60px 0 0 0;
}
.bd-course-wrapper.style-ten .bd-course-thumb-bg {
  border-radius: 70px 0 0 0;
}
.bd-course-wrapper.style-ten .bd-course-content {
  padding: 22px 22px 30px 22px;
  border-radius: 0 0 70px 0;
}
.bd-course-wrapper.style-ten .bd-course-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.bd-course-wrapper.style-ten:hover .bd-course-thumb img {
  transform: scale3d(1.07, 1.07, 1.07);
}
.bd-course-wrapper.style-eleven {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 30px;
  padding: 25px 25px 25px 25px;
  border-radius: 8px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-course-wrapper.style-eleven {
    gap: 20px;
    padding: 15px 15px 15px 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-eleven {
    grid-template-columns: repeat(1, 1fr);
  }
}
.bd-course-wrapper.style-eleven:hover .bd-course-thumb img {
  transform: scale3d(1.07, 1.07, 1.07);
}
.bd-course-wrapper.style-eleven.bg-box-shadow {
  background-color: var(--bd-white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
.bd-course-wrapper.style-eleven .bd-course-thumb {
  overflow: hidden;
  border-radius: 6px;
}
.bd-course-wrapper.style-eleven .bd-course-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  transition: all 1.1s cubic-bezier(0, 0, 0.2, 1);
}
.bd-course-wrapper.style-eleven .bd-course-price {
  margin-bottom: 5px;
}
.bd-course-wrapper.style-eleven .bd-course-title {
  margin-bottom: 15px;
}
.bd-course-wrapper.style-eleven .bd-course-description {
  margin-bottom: 20px;
}
.bd-course-wrapper.style-eleven .bd-course-rating {
  margin-bottom: 12px;
}
.bd-course-wrapper.style-eleven .bd-course-content-bottom {
  display: flex;
}
.bd-course-wrapper.style-eleven .bd-course-lesson {
  position: relative;
}
.bd-course-wrapper.style-eleven .bd-course-lesson:first-child {
  margin-right: 15px;
  padding-right: 15px;
}
.bd-course-wrapper.style-eleven .bd-course-lesson::before {
  position: absolute;
  content: " ";
  width: 1px;
  height: 20px;
  right: 0px;
  top: 50%;
  background: var(--bd-border-primary);
  transform: translateY(-50%);
}
.bd-course-wrapper.style-eleven.circle .bd-course-thumb {
  border-radius: 50%;
  width: 260px;
  height: 260px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-eleven.circle .bd-course-thumb {
    margin: 0 auto;
  }
}
.bd-course-wrapper.style-eleven.circle .bd-course-thumb img {
  width: 260px;
  height: 260px;
  border-radius: 50%;
}
.bd-course-wrapper.style-twelve {
  display: grid;
  grid-template-columns: 389px auto;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-twelve {
    grid-template-columns: auto;
  }
}
.bd-course-wrapper.style-twelve .bd-course-thumb-style {
  border-radius: 16px 0 0 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-twelve .bd-course-thumb-style {
    border-radius: 16px 16px 0 0;
  }
}
.bd-course-wrapper.style-twelve .bd-course-thumb-style .bd-course-thumb-bg {
  border-radius: 16px 0 0 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-twelve .bd-course-thumb-style .bd-course-thumb-bg {
    border-radius: 16px 16px 0 0;
  }
}
.bd-course-wrapper.style-twelve .bd-course-content {
  padding: 15px 30px;
  background-color: var(--bd-bg-primary);
  border-radius: 0 16px 16px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-twelve .bd-course-content {
    border-radius: 0 0 16px 16px;
  }
}
.bd-course-wrapper.style-twelve .bd-course-title {
  font-size: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-wrapper.style-twelve .bd-course-title {
    font-size: 26px;
    -webkit-line-clamp: 3;
  }
}
.bd-course-wrapper.style-twelve .bd-course-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}
.bd-course-wrapper.style-twelve .bd-course-price .current-price {
  font-size: 20px;
  font-weight: 600;
}
.bd-course-wrapper.style-twelve .bd-course-price .old-price {
  font-size: 18px;
  font-weight: 600;
}
.bd-course-wrapper.style-twelve .bd-course-rating-text p {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.bd-course-wrapper.style-twelve .bd-course-rating-text span {
  font-size: 18px;
  color: var(--bd-theme-black);
}
.bd-course-thumb {
  overflow: hidden;
}
.bd-course-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bd-course-info {
  background: var(--bd-white);
  padding: 30px 30px;
  min-width: 380px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  z-index: 11;
  transition: all 0.3s linear;
  border-radius: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-course-info {
    min-width: 100%;
    width: 100%;
  }
}
.bd-course-info-label {
  font-size: 18px;
  font-weight: 600;
  color: var(--bd-theme-black);
}
.bd-course-info-label span {
  color: var(--bd-primary);
}
.bd-course-info-list ul {
  margin-bottom: 30px;
  list-style: none;
}
.bd-course-info-list ul li:not(:last-child) {
  margin-bottom: 8px;
}
.bd-course-info-list ul li i {
  margin-right: 15px;
  color: var(--bd-primary);
}
.bd-course-favorite {
  position: absolute;
  top: 16px;
  left: 16px;
}
.bd-course-like {
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: var(--bd-white);
  color: var(--bd-theme-black);
  border-radius: 50%;
  transition: var(--transition);
}
.bd-course-like:hover {
  background-color: var(--bd-secondary);
  color: var(--bd-white);
}
.bd-course-like i {
  font-size: 16px;
}
.bd-course-like.has-border {
  background-color: transparent;
  border: 1px solid var(--bd-border-primary);
  transition: var(--transition);
}
.bd-course-like.has-border:hover {
  color: var(--bd-white);
  background-color: var(--bd-secondary);
  border-color: var(--bd-secondary);
}
.bd-course-like.has-bg {
  border-radius: 5px;
  color: var(--bd-white);
  background-color: rgba(255, 255, 255, 0.3);
}
.bd-course-like.has-bg:hover {
  color: var(--bd-secondary);
}
.bd-course-rating-icon {
  gap: 2px;
}
.bd-course-rating-text span {
  color: var(--bd-taxonomy-color);
}
.bd-course-price {
  display: flex;
  gap: 0 10px;
  align-items: center;
}
.bd-course-author {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bd-course-author .thumb img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid var(--bd-white);
  box-shadow: 0px 3px 10px rgba(17, 32, 81, 0.08);
}
.bd-course-author .name {
  color: var(--bd-theme-black);
  transition: all 0.3s linear;
  font-weight: var(--bd-fw-medium);
}
.bd-course-author .name:hover {
  color: var(--bd-primary);
}
.bd-course-author .subtitle {
  font-size: 14px;
  margin-bottom: 0px;
  color: var(--bd-taxonomy-color);
}
.bd-course-lesson span {
  display: inline-flex;
  color: var(--bd-taxonomy-color);
  align-items: center;
  font-size: 14px;
}
.bd-course-lesson span i {
  font-size: 16px;
  margin-right: 6px;
  color: var(--bd-primary);
}
.bd-course-student span {
  display: inline-flex;
  color: var(--bd-taxonomy-color);
  align-items: center;
  font-size: 14px;
}
.bd-course-student span i {
  font-size: 16px;
  margin-right: 6px;
  color: var(--bd-primary);
}
.bd-course-level span {
  display: inline-flex;
  color: var(--bd-taxonomy-color);
  align-items: center;
  font-size: 14px;
}
.bd-course-level span i {
  font-size: 16px;
  margin-right: 6px;
  color: var(--bd-primary);
}
.bd-course-duration span {
  display: inline-flex;
  color: var(--bd-taxonomy-color);
  align-items: center;
  font-size: 14px;
}
.bd-course-duration span i {
  font-size: 16px;
  margin-right: 6px;
  color: var(--bd-primary);
}
.bd-course-badge {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 4;
}
@media (max-width:450px) {
  .bd-course-badge {
    top: 15px;
    right: 15px;
  }
}
.bd-course-badge.badge-left {
  right: auto;
  left: 25px;
}
@media (max-width:450px) {
  .bd-course-badge.badge-left {
    left: 15px;
  }
}
.bd-course-divider {
  border-bottom: 1px solid var(--bd-border-primary);
  padding-top: 20px;
  margin-bottom: 15px;
}
.bd-course-full-border {
  position: relative;
  padding-top: 20px;
  margin-bottom: 20px;
}
.bd-course-full-border::before {
  position: absolute;
  content: "";
  left: -28px;
  height: 1px;
  background-color: var(--bd-border-primary);
  width: calc(100% + 55px);
}
.bd-course-tab-btn-wrap {
  display: flex;
  gap: 10px;
  justify-content: end;
}
.bd-course-tab-btn-wrap .bd-btn {
  padding: 0 20px;
  height: 40px;
}
.bd-course-tab-btn-wrap button.active {
  color: var(--bd-white);
  background: var(--bd-primary);
}
.bd-course-list .bd-course-wrapper:not(:last-child) {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--bd-border-primary);
}
.bd-course-widget {
  background-color: var(--bd-bg-primary);
  border-radius: 5px;
  padding: 30px 30px;
  margin-bottom: 50px;
}
.bd-course-widget:not(:last-child) {
  margin-bottom: 30px;
}
.bd-course-filter-search-form {
  position: relative;
}
.bd-course-filter-search-form input[type=text] {
  outline: none;
  background-color: transparent;
  height: 50px;
  width: 100%;
  font-size: 16px;
  border: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--bd-border-primary);
  color: var(--bd-theme-black);
  padding-inline-start: 25px;
  padding-inline-end: 50px;
}
.bd-course-filter-search-form button {
  font-size: 20px;
  color: var(--bd-theme-black);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.bd-course-filter-search-form button:hover {
  background-color: var(--bd-bg-primary);
  color: var(--bd-primary);
}
.bd-course-filter-content {
  display: none;
}
.bd-course-filter-widget {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  background-color: var(--bd-bg-primary);
  border-radius: 5px;
  padding: 30px 30px;
  margin-bottom: 30px;
}

.bd-widget-content .checkbox-option:not(:last-child) {
  margin-bottom: 15px;
}

.bd-new-course-info {
  background-color: var(--primary-rgb-05);
  padding: 30px 30px;
  border-radius: 12px;
}
.bd-new-course-info-list {
  list-style-type: none;
}
.bd-new-course-info-list li {
  margin-left: 30px;
  position: relative;
}
.bd-new-course-info-list li:not(:last-child) {
  margin-bottom: 15px;
}
.bd-new-course-info-list .icon {
  width: 20px;
  height: 20px;
  background-color: var(--primary-rgb-1);
  color: var(--bd-primary);
  position: absolute;
  left: -30px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 50%;
}

.bd-new-course-general-item:not(:last-child) {
  margin-bottom: 20px;
}
.bd-new-course-general-item .form-input-box {
  display: grid;
  grid-template-columns: 200px auto;
  align-items: baseline;
}

.bd-radio-switcher label * {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #5F6168;
  position: relative;
}
.bd-radio-switcher label input {
  display: none;
}
.bd-radio-switcher label input + span {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  background-color: var(--bd-bg-primary);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  flex: 0 0 auto;
}
.bd-radio-switcher label input + span::before {
  position: absolute;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  left: 4px;
  top: 3px;
  background-color: var(--bd-light);
}
.bd-radio-switcher label input:checked + span {
  background-color: var(--bd-primary);
}
.bd-radio-switcher label input:checked + span::before {
  left: 56%;
}

.bd-course-attachments-input {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--bd-primary);
  padding: 10px 19px;
  border-radius: 6px;
  background: transparent;
  display: inline-block;
  border: 1px solid var(--bd-primary);
  transition: var(--transition);
}
.bd-course-attachments-input:hover {
  background-color: var(--bd-primary);
  border-color: var(--bd-primary);
  color: var(--bd-white);
}
.bd-course-attachments-input input {
  display: none;
}
.bd-course-attachments-input label {
  cursor: pointer;
  margin-bottom: 0;
}
.bd-course-attachments-input span {
  display: inline-block;
  margin-right: 5px;
}

.modal-content {
  padding: 10px 20px;
}

.bd-course-product-item {
  display: grid;
  grid-template-columns: 300px auto;
  align-items: center;
  gap: 30px 0;
}
.bd-course-product-item:not(:last-child) {
  margin-bottom: 20px;
}
.bd-course-product-item .title {
  color: var(--bd-theme-black);
}

.bd-course-thumbnail {
  width: 790px;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
}
.bd-course-thumbnail-label {
  position: relative;
  overflow: hidden;
}
.bd-course-thumbnail-label:hover::before {
  opacity: 1;
  visibility: visible;
}
.bd-course-thumbnail-label:hover .label-title {
  opacity: 1;
  visibility: visible;
}
.bd-course-thumbnail-label input {
  display: none;
}
.bd-course-thumbnail-label label {
  cursor: pointer;
  position: relative;
}
.bd-course-thumbnail-label::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: var(--bd-primary);
  z-index: 5;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.bd-course-thumbnail-label .label-title {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  color: var(--bd-white);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  font-size: 20px;
  visibility: hidden;
  text-align: center;
}
.bd-course-thumbnail-preview {
  width: 790px;
  height: 300px;
  position: relative;
  display: inline-flex;
}
.bd-course-thumbnail-preview-box {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bd-course-video-lessons {
  display: inline-block;
  width: 100%;
}

.bd-course-intro-video {
  display: inline-block;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-course-list.with-sidebar .bd-course-wrapper.style-twelve {
    grid-template-columns: auto;
  }
  .bd-course-list.with-sidebar .bd-course-wrapper.style-twelve .bd-course-thumb-style .bd-course-thumb-bg {
    border-radius: 16px 16px 0 0;
  }
  .bd-course-list.with-sidebar .bd-course-wrapper.style-twelve .bd-course-thumb-style {
    height: 320px;
  }
  .bd-course-list.with-sidebar .bd-course-wrapper.style-twelve .bd-course-content {
    border-radius: 0 0 16px 16px;
  }
}

.bd-course-slider-navigation button {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: transparent;
  border: 1px solid var(--bd-primary);
  border-radius: 6px;
  color: var(--bd-primary);
  transition: all 0.3s ease-in-out;
}
.bd-course-slider-navigation button:hover {
  background-color: var(--bd-primary);
  color: var(--bd-white);
}

/*----------------------------------------*/
/*  4.8 courses details css
/*----------------------------------------*/
.bd-course-details-top {
  margin-top: -60px;
  position: relative;
  z-index: 15;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-details-top {
    margin-top: 0;
  }
}
.bd-course-details-wrapper {
  padding: 40px 30px 0 30px;
  background: var(--bd-white);
  border-radius: 5px;
}
@media (max-width: 575px) {
  .bd-course-details-wrapper {
    padding: 40px 0 0 0;
  }
}
.bd-course-details-content-title {
  font-size: 26px;
  margin-bottom: 10px;
}
.bd-course-details-rating {
  display: flex;
  align-items: center;
  gap: 15px;
}
.bd-course-details-rating ul li {
  list-style: none;
  display: inline-block;
  color: var(--bd-rating-color);
}
.bd-course-details-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid var(--bd-border-primary);
  border-bottom: 1px solid var(--bd-border-primary);
  align-items: center;
}
.bd-course-details-meta .bd-course-author {
  display: flex;
  align-items: center;
  gap: 15px;
}
.bd-course-details-meta .bd-course-author .thumb img {
  width: 50px;
  height: 50px;
}
.bd-course-details-meta.border-padding-none {
  gap: 30px;
  padding: 0 0;
  border-top: none;
  border-bottom: none;
  justify-content: start;
}
.bd-course-details-meta-item .title {
  font-size: 14px;
  margin-bottom: 0px;
}
.bd-course-details-meta-item .subtitle {
  display: block;
  font-weight: 700;
  color: var(--bd-theme-black);
}
.bd-course-details-list-box {
  border-radius: 8px;
  padding: 30px 30px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--bd-bg-primary);
  gap: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-details-list-box {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}
.bd-course-details-list-box::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  background-color: var(--bd-border-primary);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-course-details-list-box::after {
    display: none;
  }
}
.bd-course-details-list ul li {
  list-style: none;
  display: flex;
  gap: 10px;
}
.bd-course-details-list ul li span {
  margin-top: 4px;
}
.bd-course-details-list ul li:not(:last-child) {
  margin-bottom: 15px;
}
.bd-course-details-language {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.bd-course-details-language .bd-course-details-rating {
  gap: 8px;
}

.bd-course-add-time {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px 30px;
}

.bd-course-curriculum {
  /*curriculum two */
}
.bd-course-curriculum .accordion-common-style .accordion-item:not(:last-child) {
  border-bottom: none;
}
.bd-course-curriculum-overview {
  color: var(--bd-taxonomy-color);
  margin-bottom: 15px;
  display: block;
}
.bd-course-curriculum-content:not(:last-child) {
  margin-bottom: 15px;
}
.bd-course-curriculum-content:hover .bd-course-curriculum-info .title {
  color: var(--bd-primary);
}
.bd-course-curriculum-info .title {
  font-size: 16px;
  font-weight: var(--bd-fw-regular);
  color: var(--bd-theme-black);
}
.bd-course-curriculum-info .icon {
  color: var(--bd-text-muted);
  font-size: 14px;
}
.bd-course-curriculum-meta .duration {
  font-size: 16px;
  font-weight: var(--bd-fw-regular);
  color: var(--bd-theme-black);
}
.bd-course-curriculum-meta .status {
  color: var(--bd-text-muted);
  font-size: 14px;
}
.bd-course-curriculum-accordion .accordion-sl-number .accordion-button {
  padding: 10px 30px;
  padding-left: 50px;
}
.bd-course-curriculum-accordion .accordion-sl-number .accordion-button::before {
  inset-inline-start: -8px;
  font-size: 38px;
}
.bd-course-curriculum-accordion .accordion-sl-number .accordion-button::after {
  display: block;
}
.bd-course-curriculum-accordion .accordion-sl-number .accordion-button:not(.collapsed)::after {
  display: block;
}
.bd-course-curriculum-accordion .accordion-sl-number .accordion-body {
  padding: 10px 25px;
}

.bd-course-instructors {
  padding: 20px 30px 25px 30px;
  background: var(--bd-bg-primary);
}
.bd-course-instructors-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}
.bd-course-instructors-content .thumb {
  width: 170px;
  height: 170px;
  min-width: 170px;
}
.bd-course-instructors-content .thumb img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.bd-course-instructors-content.style-two .thumb {
  width: 230px;
  height: 230px;
  min-width: 230px;
}
.bd-course-instructors-content.style-two .thumb img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}
.bd-course-instructors-info {
  margin-bottom: 5px;
}
.bd-course-instructors-info .name {
  font-size: 18px;
}
.bd-course-instructors-info .designation {
  color: var(--bd-text-muted);
  font-size: 16px;
}
.bd-course-instructors-info .meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}
.bd-course-instructors-rating {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.bd-course-instructors-rating .icon {
  color: var(--bd-rating-color);
}
.bd-course-instructors-rating span {
  color: var(--bd-taxonomy-color);
}
.bd-course-instructors-course {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--bd-body-text);
}

.border-line-meta {
  position: relative;
}
.border-line-meta::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 40px;
  background: var(--bd-border-primary);
  right: -45px;
  top: 10px;
}
@media (max-width: 575px) {
  .border-line-meta::before {
    display: none;
  }
}

.list-icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  background: var(--bd-primary);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 50%;
  color: var(--bd-white);
}
.list-icon.success {
  color: var(--bd-success);
  background: rgba(6, 231, 117, 0.2);
}
.list-icon.danger {
  color: var(--bd-danger-bg);
  background: rgba(235, 0, 27, 0.2);
}
.list-icon.white {
  color: var(--bd-white);
  background: rgba(255, 255, 255, 0.2);
}

.bd-course-sidebar-widget {
  padding: 30px 30px 30px 30px;
  background-color: var(--bd-white);
  box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
  border-radius: 5px;
}
.bd-course-sidebar-widget.transparent {
  background-color: transparent;
  box-shadow: none;
  padding: 0 0;
  border-radius: 0;
}
.bd-course-sidebar-widget .current-price {
  font-size: 30px;
  color: var(--bd-theme-black);
  font-weight: var(--bd-fw-bold);
}
.bd-course-sidebar-widget .old-price {
  font-size: 22px;
  font-weight: var(--bd-fw-bold);
}
.bd-course-sidebar-widget .thumb-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bd-course-sidebar-widget .bd-course-circle-price {
  position: absolute;
  inset-inline-end: 20px;
  bottom: 20px;
  z-index: 2;
}
.bd-course-sidebar-widget .bd-discount-time {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--bd-danger);
  font-size: 14px;
}
.bd-course-sidebar-widget-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--bd-border-primary);
  margin-bottom: 12px;
}
.bd-course-sidebar-widget-list ul li .icon {
  display: flex;
  align-items: center;
}
.bd-course-sidebar-widget-list ul li .icon i {
  line-height: 1;
  margin-right: 15px;
  color: var(--bd-body-text);
}
.bd-course-sidebar-widget-btn .bd-btn {
  padding: 0 20px;
}
.bd-course-sidebar-widget-share .bd-post-share {
  border-radius: 6px;
  background: var(--bd-bg-primary);
  padding: 20px 20px;
}

.bd-course-menu {
  position: sticky;
  top: 100px;
  z-index: 15;
  background-color: var(--bd-white);
  padding: 20px 20px;
  border: 1px solid var(--bd-border-primary);
  border-radius: 6px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
}
.bd-course-menu-nav ul {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  gap: 5px 50px;
}
@media (max-width: 575px) {
  .bd-course-menu-nav ul {
    gap: 5px 10px;
  }
}
.bd-course-menu-nav ul li {
  list-style: none;
}
.bd-course-menu-nav ul li a {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  color: var(--bd-theme-black);
}
.bd-course-menu-nav ul li.active a {
  color: var(--bd-primary);
}

.bd-course-feature-box {
  padding: 25px 40px 30px;
  border-radius: 6px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--bd-white);
}
@media (max-width: 575px) {
  .bd-course-feature-box {
    padding: 30px 30px;
  }
}
.bd-course-feature-box .bd-course-details-content-title {
  margin-bottom: 30px;
}

.bd-course-add-time-item p {
  font-size: 16px;
  color: var(--bd-label-color);
}
.bd-course-add-time-item .date {
  color: var(--bd-theme-black);
  font-weight: var(--bd-fw-medium);
}
.bd-course-add-time-item .icon {
  height: 30px;
  width: 30px;
  text-align: center;
  display: inline-flex;
  background-color: var(--bd-primary);
  margin-right: 8px;
  border-radius: 100%;
  color: var(--bd-white);
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.course-sidebar-top {
  margin-top: -452px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .course-sidebar-top {
    margin-top: 0;
  }
}

.bd-course-sidebar-widget-thumb img {
  border-radius: 8px;
}

/*----------------------------------------*/
/*  4.10 call to action css
/*----------------------------------------*/
.bd-cta-wrapper.style-one .bd-cta-item {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  padding: 50px 30px;
  display: flex;
  gap: 30px;
  align-items: center;
}
@media (max-width: 575px) {
  .bd-cta-wrapper.style-one .bd-cta-item {
    flex-direction: column-reverse;
  }
}
.bd-cta-wrapper.style-one .bd-cta-item.bg-1 {
  background-color: var(--primary-rgb-15);
}
.bd-cta-wrapper.style-one .bd-cta-item.bg-1::before {
  position: absolute;
  content: "";
  top: -50px;
  right: -50px;
  width: 216px;
  height: 216px;
  background-color: var(--primary-rgb-15);
  border-radius: 100%;
}
.bd-cta-wrapper.style-one .bd-cta-item.bg-2 {
  background-color: var(--secondary-rgb-15);
}
.bd-cta-wrapper.style-one .bd-cta-item.bg-2::before {
  position: absolute;
  content: "";
  top: -50px;
  right: -50px;
  width: 216px;
  height: 216px;
  background-color: var(--secondary-rgb-15);
  border-radius: 100%;
}
.bd-cta-wrapper.style-one .bd-cta-item:hover .bd-cta-bg img {
  transform: scale(1.05);
}
.bd-cta-wrapper.style-one .bd-cta-content {
  position: relative;
  width: 335px;
  z-index: 1;
}
@media (max-width: 575px) {
  .bd-cta-wrapper.style-one .bd-cta-content {
    width: 100%;
  }
}
.bd-cta-wrapper.style-one .bd-cta-tittle {
  margin-bottom: 30px;
}
.bd-cta-wrapper.style-one .bd-cta-subtitle {
  margin-bottom: 10px;
  display: block;
  color: var(--bd-primary);
  font-weight: 700;
  text-transform: uppercase;
}
.bd-cta-wrapper.style-two .bd-cta-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bd-cta-wrapper.style-two .bd-cta-bg-overlay::after {
  content: "";
  background: var(--bd-primary);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.bd-cta-wrapper.style-two .bd-cta-shape-1 {
  position: absolute;
  inset-inline-start: 0;
  top: 0;
}
.bd-cta-wrapper.style-two .bd-cta-shape-2 {
  position: absolute;
  inset-inline-start: 230px;
  top: 50px;
  animation: scaleZeroOne 5s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite alternate both;
}
.bd-cta-wrapper.style-two .bd-cta-shape-3 {
  position: absolute;
  top: 50px;
  inset-inline-end: 80px;
  animation: scaleZeroOne 6s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite alternate both;
}
.bd-cta-wrapper.style-two .bd-cta-shape-4 {
  position: absolute;
  inset-inline-end: 450px;
  top: 30px;
  animation: scaleZeroOne 5s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite alternate both;
}
.bd-cta-wrapper.style-two .bd-cta-shape-5 {
  position: absolute;
  inset-inline-start: 110px;
  bottom: 88px;
  animation: scaleZeroOne 6s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite alternate both;
}
.bd-cta-wrapper.style-two .bd-cta-shape-6 {
  position: absolute;
  inset-inline-start: 440px;
  bottom: 127px;
  animation: scaleZeroOne 6s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite alternate both;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-cta-wrapper.style-two .bd-cta-shape-6 {
    inset-inline-start: 440px;
    bottom: 40px;
  }
}
.bd-cta-wrapper.style-two .bd-cta-shape-7 {
  position: absolute;
  inset-inline-end: 287px;
  bottom: 83px;
  animation: scaleZeroOne 5s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite alternate both;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-cta-wrapper.style-two .bd-cta-shape-7 {
    inset-inline-end: 165px;
    bottom: 83px;
  }
}
.bd-cta-wrapper.style-two .bd-cta-shape-8 {
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
}
.bd-cta-wrapper.style-two .bd-section-paragraph {
  max-width: 550px;
  margin: 0 auto;
}
.bd-cta-wrapper.style-three .thumb img {
  position: absolute;
  left: 0;
  border: 4px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin-right: 0;
}
.bd-cta-wrapper.style-three .bd-cta-box {
  display: grid;
  grid-template-columns: 560px auto;
  align-items: center;
  padding: 60px 60px 60px 60px;
  background-color: var(--bd-primary);
  border-radius: 8px;
  justify-content: space-between;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-cta-wrapper.style-three .bd-cta-box {
    grid-template-columns: 400px auto;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-cta-wrapper.style-three .bd-cta-box {
    grid-template-columns: auto;
    gap: 30px;
    padding: 60px 30px 60px 30px;
    justify-content: center;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-cta-wrapper.style-three .bd-cta-box {
    grid-template-columns: auto;
    gap: 30px;
    padding: 60px 20px 60px 20px;
    justify-content: center;
    text-align: center;
  }
}
.bd-cta-wrapper.style-three .number {
  font-size: 34px;
  font-weight: 700;
  color: var(--bd-white);
}
.bd-cta-wrapper.style-four {
  border-radius: 6px;
  padding: 50px 50px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-cta-wrapper.style-four {
    padding: 50px 30px;
  }
}
.bd-cta-wrapper.style-four::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
}
.bd-cta-wrapper.style-four .bd-cta-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-cta-wrapper.style-four .bd-cta-item {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
}
.bd-cta-wrapper.style-four .bd-cta-content .title {
  margin-bottom: 0;
  color: var(--bd-white);
}
.bd-cta-wrapper.style-four .bd-cta-content .subtitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--bd-white);
}
.bd-cta-wrapper.style-four .bd-cta-separator {
  margin: 0 30px;
}
.bd-cta-wrapper.style-four .bd-cta-separator span {
  display: block;
  text-align: center;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--bd-primary);
  background-color: var(--bd-white);
}
.bd-cta-wrapper.style-four .bd-cta-separator span img {
  width: 36px;
  height: 36px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-cta-wrapper.style-five .bd-cta-content {
    padding-top: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-cta-wrapper.style-five .bd-cta-content {
    padding-top: 65px;
  }
}
.bd-cta-wrapper.style-five .bd-cta-thumb-wrapper {
  position: relative;
  z-index: 1;
  max-width: 510px;
  margin: 10px auto 0;
}
.bd-cta-wrapper.style-five .bd-cta-thumb-shape {
  z-index: -1;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 30px 30px;
  top: 50%;
  left: 55%;
  width: 388px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.bd-cta-wrapper.style-five .bd-cta-thumb-shape img {
  width: 100%;
}
.bd-cta-wrapper.style-six .bd-cta-thumb {
  position: absolute;
  bottom: -3px;
  right: 75px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .bd-cta-wrapper.style-six .bd-cta-thumb {
    right: 0px;
    width: 800px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-cta-wrapper.style-six .bd-cta-thumb {
    right: 0px;
    width: 700px;
  }
}
.bd-cta-wrapper.style-six .bd-cta-shape-wrap .shape-1 {
  position: absolute;
  top: 80px;
  left: 140px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .bd-cta-wrapper.style-six .bd-cta-shape-wrap .shape-1 {
    top: 30px;
  }
}
.bd-cta-wrapper.style-six .bd-cta-shape-wrap .shape-2 {
  position: absolute;
  left: 70px;
  bottom: 40px;
}
.bd-cta-wrapper.style-six .bd-cta-shape-wrap .shape-3 {
  position: absolute;
  left: 50%;
  bottom: 200px;
  transform: translateX(-50%);
}
.bd-cta-wrapper.style-six .bd-cta-shape-wrap .shape-4 {
  position: absolute;
  right: 35px;
  bottom: 25px;
}

.bd-cta-section-wave {
  position: relative;
}
.bd-cta-section-wave::before {
  position: absolute;
  content: "";
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 40px;
  top: -10px;
  left: 0;
}

.bd-cta-five-shape .shape-1 {
  position: absolute;
  left: 0;
  top: 0;
}
.bd-cta-five-shape .shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bd-cta-content .bd-course-details-list ul li {
  color: var(--bd-white);
  align-items: center;
}
.bd-cta-content .bd-course-details-list ul li span {
  margin-top: 0;
}

/*----------------------------------------*/
/*  4.11 Demo css
/*----------------------------------------*/
.bd-dashboard-area {
  margin-top: -65px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-dashboard-area {
    margin-top: 65px;
  }
}

.bd-dashboard-area .table > :not(caption) > * > * {
  padding: 15px 15px;
}

.bd-dashboard-profile-user {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-dashboard-profile-user {
    gap: 20px;
  }
}
@media (max-width: 575px) {
  .bd-dashboard-profile-user {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    text-align: center;
  }
}
.bd-dashboard-profile-user .thumb {
  width: 160px;
  height: 160px;
  border: 4px solid var(--bd-white);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}
.bd-dashboard-profile-user .thumb img {
  width: 100%;
  height: 100%;
}
.bd-dashboard-profile-user .content .name {
  color: var(--bd-white);
  font-size: 24px;
  margin-bottom: 5px;
}
.bd-dashboard-profile-user .content .designation {
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 10px;
}
.bd-dashboard-profile-user .content .bd-course-rating-text span {
  color: var(--bd-white);
}
.bd-dashboard-profile-user .bd-dashboard-profile-meta {
  display: flex;
  align-items: center;
  gap: 10px 20px;
  flex-wrap: wrap;
}
@media (max-width: 575px) {
  .bd-dashboard-profile-user .bd-dashboard-profile-meta {
    justify-content: center;
  }
}
.bd-dashboard-profile-user .bd-dashboard-profile-meta .enrolled-course,
.bd-dashboard-profile-user .bd-dashboard-profile-meta .complete-course {
  display: flex;
  align-items: center;
  gap: 0 10px;
  color: var(--bd-white);
}

.bd-dashboard-profile {
  display: flex;
  align-items: end;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 70px;
  width: calc(100% - 210px);
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-dashboard-profile {
    width: calc(100% - 40px);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-dashboard-profile {
    flex-wrap: wrap;
    gap: 30px;
    width: calc(100% - 50px);
    justify-content: center;
  }
}

/* New */
.bd-dashboard-earnings-box .bd-counter-title,
.bd-dashboard-status-box .bd-counter-title {
  font-size: 38px;
}
.bd-dashboard-earnings-box .bd-counter-style-six .bd-counter-content,
.bd-dashboard-status-box .bd-counter-style-six .bd-counter-content {
  padding: 30px 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-dashboard-earnings-box .bd-counter-style-six .bd-counter-content,
  .bd-dashboard-status-box .bd-counter-style-six .bd-counter-content {
    padding: 15px 15px;
  }
}

.bd-dashboard-course-table {
  overflow-x: auto;
}

.rbt-btn.rbt-marquee-btn.marquee-auto span {
  animation: btnTxtMarqueeX 2s linear infinite;
}
.rbt-btn.rbt-marquee-btn span {
  display: inline-flex
;
  position: relative;
  z-index: 3;
  line-height: 1.2;
}

.rbt-btn.rbt-marquee-btn span::after {
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 100%;
  transform: translate(100%, -50%);
}

.rbt-btn.btn-border-gradient.radius-round::before {
  border-radius: 500px;
}

.rbt-btn.btn-border-gradient::before {
  content: "";
  z-index: -1;
  top: 3px;
  left: 3px;
  position: absolute;
  background: #fff;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
}

.rbt-btn.btn-border-gradient {
  background: linear-gradient(90deg, #CFA2E8, #637FEA);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 10;
  color: #192335;
  box-shadow: 0 10px 13px 0 rgba(209, 211, 237, 0.39);
  border-radius: 0;
}

.rbt-btn-wrapper {
  overflow: hidden;
  position: relative;
  width: 200px; /* Adjust width as needed */
}

.rbt-marquee-btn span {
  display: inline-block;
  white-space: nowrap;
  animation: btnTxtMarqueeX 5s linear infinite;
}

@keyframes btnTxtMarqueeX {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bd-course-thumb-style {
  display: block;
  overflow: hidden;
}

.p-relative {
  position: relative;
}

.bd-course-thumb-style .bd-course-thumb-bg {
  padding: 5px;
  height: 100% !important;
  border-radius: 15px;
}

.bd-course-wrapper.style-two {
  border: 1px solid #B5B5C3;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 50px;
}

.bd-course-thumb-style.small-style .bd-course-overly-title {
  font-size: 50px;
}

.bd-course-btn .btn-outline-primary {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 10px 19px;
  align-items: center;
  gap: 6px;
  border-radius: 50px;
  border: 1px solid #07a169 ;
  background: var(--tg-theme-secondary);
  box-shadow: 4px 4px 0px 0px #07a169;
  font-family: var(--tg-heading-font-family);
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--tg-fw-semi-bold);
  color: var(--tg-heading-color);
}

.tp-course-rating-icon i {
  font-size: 13px;
  color: #FFB21D;
}

.bd-course-thumb-wrapper.bd-course-thumb-style.small-style.p-relative {
  padding: 10px;
}

.bd-top-sorting-left {
  margin: 50px 0;
}
.bd-course-more-btn.d-flex.justify-content-center.mt-50 {
  margin-top: 30px;
}

.btn-outline-border-primary {
  background-color: #07a169;
  color: #fff;
  border: 1px solid #07a169;
  font-size: 16px;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 50px;
  transition: all 0.3slinear;
  border-radius: 6px;
    white-space: nowrap;
}

.rating-color i {
  color: orange;
}

.bd-course-description {
  line-height: 1.5;
  max-height: 3em; /* Adjust for consistency */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits to 3 lines */
  -webkit-box-orient: vertical;
}

/* .courses__item:hover {
  filter: drop-shadow(10px 10px 0px #CAC9D6);
} */


/* List View Styling */
.list-style {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease-in-out;
  gap: 20px;
}

.list-style .bd-course-thumb-wrapper {
  flex: 0 0 300px; /* Increase image width */
  max-width: 300px;
  height: 180px; /* Increase height */
  overflow: hidden;
  border-radius: 12px;
}

.list-style .bd-course-thumb-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.list-style .bd-course-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-style .bd-course-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.list-style .bd-course-description {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  max-width: 90%;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .list-style .bd-course-thumb-wrapper {
      flex: 0 0 250px; /* Slightly reduce image size */
      max-width: 250px;
      height: 160px;
  }
}

@media (max-width: 768px) {
  .list-style {
      flex-direction: column;
      text-align: center;
      padding: 15px;
  }

  .list-style .bd-course-thumb-wrapper {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin-bottom: 15px;
  }

  .list-style .bd-course-description {
      max-width: 100%;
  }
}

.course-details {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* Course Banner */
.course-banner {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 12px;
}

.course-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-title-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 15px;
  border-radius: 8px;
}

.course-title-overlay h1 {
  margin: 0;
  font-size: 1.8rem;
}

.course-title-overlay p {
  margin: 5px 0 0;
  font-size: 1rem;
}

/* Course Content */
.course-content {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.course-info {
  flex: 2;
}

.course-info h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.course-meta {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.course-meta li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
}

.rating-star {
  color: #FFD700;
}

/* Sidebar */
.course-sidebar {
  flex: 1;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
}

.course-sidebar img {
  width: 100%;
  border-radius: 12px;
}

.course-price {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 15px 0;
}

.course-price del {
  color: red;
  margin-right: 10px;
}

.course-instructor {
  margin-top: 20px;
}

.course-instructor img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* Enroll Button */
.enroll-button {
  margin-top: 20px;
}

.enroll-button a {
  background: #007bff;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
}

.enroll-button a:hover {
  background: #0056b3;
}

/* Responsive */
@media (max-width: 768px) {
  .course-content {
      flex-direction: column;
  }

  .course-sidebar {
      text-align: center;
  }
}


section.bd-course-area.section-space {
  background-color: #f2f2f2 !important;
}


.bd-course-thumb-style .bd-course-thumb-bg img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

.bd-course-thumb-style .bd-course-thumb-bg.bg-1 {
  background-color: #D5EFDE;
}
.bd-course-thumb-style .bd-course-thumb-bg {
  padding: 0px;
  height: 100% !important;
  border-radius: 15px;
}
.bd-course-thumb-wrapper.bd-course-thumb-style.small-style.p-relative {
  padding: 0px;
}


.bd-course-wrapper.style-two {
  border: 1px solid #B5B5C3;
  border-radius: 10px;
  padding: 0px;
  margin-bottom: 50px;
}

.rating-color i {
  font-size: 15px;
}

/* Add padding to each course item */
.courses__item {
  padding: 20px; /* Adjust the padding value as needed */
}

/* Optional: Different padding for grid and list views */
.display-layout-grid .courses__item {
  padding: 20px; /* Padding for grid view */
}

.display-layout-list .courses__item {
  padding: 15px; /* Padding for list view */
}

/* Hover Animation for bd-course-wrapper */
.bd-course-wrapper {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.bd-course-wrapper:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Smooth Hover Animation for bd-course-wrapper */
.bd-course-wrapper {
  transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), 
              box-shadow 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94),
              opacity 0.4s ease; /* Added opacity for extra smoothness */
  transform-origin: center; /* Ensure scaling happens from the center */
  will-change: transform, box-shadow; /* Optimize for performance */
}

.bd-course-wrapper:hover {
  transform: scale(1.03); /* Slightly scale up */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15); /* Softer shadow */
  opacity: 0.95; /* Slight fade for a smooth effect */
}

/* Container for the progress bar and circle */
.progress-bar-container {
  position: relative;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
}

/* Progress bar (if needed) */
.progress-bar {
  width: 100%;
  height: 10px; /* Adjust as needed */
  background-color: #e0e0e0; /* Default background */
  border-radius: 5px;
  overflow: hidden;
}

/* Container for the progress circle */
.paginacontainer {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Progress circle wrapper */
.progress-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

/* SVG circle */
.progress-circle {
  transform: rotate(-90deg); /* Rotate to start from the top */
}

.progress-circle path {
  fill: none;
  stroke: #07a169; /* Progress color */
  stroke-width: 10; /* Adjust thickness */
  stroke-dasharray: 308; /* Circumference of the circle (2 * π * r) */
  stroke-dashoffset: 308; /* Start with no progress */
  transition: stroke-dashoffset 0.5s ease; /* Smooth animation */
}

/* Arrow inside the circle */
.top-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}

.top-arrow img {
  width: 100%;
  height: 100%;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366; /* WhatsApp green */
  color: white;
  padding: 15px;
  border-radius: 50%;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-size: 24px;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Darker green on hover */
}

a {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

a:hover {
  color: #007bff;
}

.enroll-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.enroll-form h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #07a169;
  box-shadow: 0 0 0 2px rgba(7, 161, 105, 0.2);
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #07a169;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #055c3d;
}

.success-message {
  text-align: center;
  color: #07a169;
  font-size: 1.2rem;
  font-weight: 500;
}

.tekup-default-btn-course {
  width:  60%;
  padding: 15px 38px;
  border-radius: 5px;
}

/* FAQ Section - Professional Style */
.pro-faq-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.faq-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #0d0e1d !important;
  position: relative;
  padding-bottom: 10px;
}

.faq-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: #07a169;
}

.faq-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.faq-card {
  border-bottom: 1px solid #eaeaea;
  background: white;
  transition: all 0.3s ease;
}

.faq-card:last-child {
  border-bottom: none;
}

.faq-card.active {
  background: #f9f9f9;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-card.active .faq-header {
  padding-bottom: 1rem;
}

.faq-question {
  font-size: 22px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
  flex: 1;
}

.faq-card.active .faq-question {
  /* color: #07a169; */
}

.faq-icon {
  margin-left: 1rem;
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.faq-card.active .faq-icon {
  transform: rotate(180deg);
}

.faq-content {
  overflow: hidden;
}

.faq-answer {
  padding: 0 1.5rem 1.5rem;
  color: #555;
  line-height: 1.6;
}

.faq-extra {
  background: rgba(7, 161, 105, 0.05);
  border-left: 3px solid #07a169;
  font-size: 0.9rem;
}

/* Hover effects */
.faq-card:not(.active):hover {
  background: #f5f5f5;
}

.faq-card:hover .faq-question {
  color: #07a169;
}

/* Professional Enroll Button */
.enroll-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  background: #07a169; /* Your brand color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(7, 161, 105, 0.7);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(7, 161, 105, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(7, 161, 105, 0);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

/* Hover Effects */
.enroll-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.enroll-button:active {
  transform: translateY(0);
}

/* Gradient Overlay */
.enroll-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 100%);
  z-index: 1;
}

/* Optional Premium Effects */
.enroll-button {
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Ripple Click Effect */
.enroll-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.enroll-button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(20, 20);
    opacity: 0;
  }
}
