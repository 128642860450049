/* .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.slide-in {
    transform: translateY(20px);
    opacity: 0;
    animation: slideIn 0.8s ease-out forwards;
}

@keyframes slideIn {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.zoom-in {
    transform: scale(0.9);
    opacity: 0;
    animation: zoomIn 0.8s ease-out forwards;
}

@keyframes zoomIn {
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.pulse {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

*/


.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.zoom-in {
    transition: transform 0.3s ease;
}

.zoom-in:hover {
    transform: scale(1.05);
}

.pulse {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.slide-in {
    opacity: 0;
    transform: translateY(20px);
    animation: slideIn 0.5s ease-out forwards;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}