.course-details {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.course-banner {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
}

.course-header {
    text-align: center;
    margin-bottom: 20px;
}

.course-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.course-meta {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 1.2rem;
    color: #666;
}

.course-description {
    line-height: 1.6;
    margin-bottom: 20px;
}

.enroll-button {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: 20px auto;
    padding: 15px 5px;
    font-size: 1.2rem;
    background-color: #07a169;
    color: white;
    border: none;
    cursor: pointer;
}

.enroll-button:hover {
    background-color: #0056b3;
}

.faq-section {
    margin-top: 40px;
}

.faq-item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.faq-question {
    padding: 15px;
    font-weight: bold;
    cursor: pointer;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question:hover {
    background-color: #f1f1f1;
}

.faq-answer {
    padding: 0 15px;
    line-height: 1.6;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.error-message {
    text-align: center;
    color: #ff0000;
    margin-top: 50px;
}


/* FAQ Section */
/* .faq-section {
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
}

.faq-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 1.5rem;
    text-align: center;
}

.faq-item {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.faq-item.active {
    border-color: #4299e1;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: #2d3748;
}

.faq-question .faq-icon {
    margin-right: 1rem;
    color: #4299e1;
    transition: transform 0.3s ease;
}

.faq-item.active .faq-question .faq-icon {
    transform: rotate(180deg);
}

.faq-question .faq-text {
    flex: 1;
}

.faq-answer {
    padding: 0 1rem 1rem 1rem;
    color: #4a5568;
    line-height: 1.6;
    font-size: 0.95rem;
}

/* FAQ Section */
/* .faq-section {
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
}

.faq-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 1.5rem;
    text-align: center;
}

.faq-item {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.faq-item.active {
    border-color: #4299e1;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: #2d3748;
}

.faq-question .faq-icon {
    margin-right: 1rem;
    color: #4299e1;
    transition: transform 0.3s ease;
}

.faq-item.active .faq-question .faq-icon {
    transform: rotate(180deg);
}

.faq-question .faq-text {
    flex: 1;
}

.faq-answer {
    padding: 0 1rem 1rem 1rem;
    color: #4a5568;
    line-height: 1.6;
    font-size: 0.95rem;
} 

.faq-section {
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    background-color: #07a169;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.faq-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffffff; 
    margin-bottom: 1.5rem;
    text-align: center;
}

.faq-item {
    background: #ffffff; 
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden; 
}

.faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.faq-item.active {
    border-color: #07a169; 
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: #2d3748;
}

.faq-question .faq-icon {
    margin-right: 1rem;
    color: #07a169; 
    transition: transform 0.3s ease;
}

.faq-item.active .faq-question .faq-icon {
    transform: rotate(180deg);
}

.faq-question .faq-text {
    flex: 1;
    color: #2d3748; 
}

.faq-answer {
    padding: 1rem; 
    color: #4a5568; 
    line-height: 1.6; 
    font-size: 0.95rem;
    background-color: #f9f9f9; 
    border-top: 1px solid #e0e0e0; 
    overflow: hidden; 
    transition: all 0.3s ease; 
} */

/* FAQ Section */
.faq-section {
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    background-color: #07a169; /* Green background */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.faq-title {
    font-weight: 600;
    color: #ffffff; /* White text for contrast */
    margin-bottom: 1.5rem;
    text-align: center;
}

.faq-item {
    background: #ffffff; /* White background for FAQ items */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden; /* Ensure content doesn't overflow */
}

.faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.faq-item.active {
    border-color: #07a169; /* Green border for active FAQ */
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: #2d3748; /* Dark text for question */
}

.faq-question .faq-icon {
    margin-right: 1rem;
    color: #07a169; /* Green icon */
    transition: transform 0.3s ease;
}

.faq-item.active .faq-question .faq-icon {
    transform: rotate(180deg);
}

.faq-question .faq-text {
    flex: 1;
    color: #2d3748; /* Dark text for question */
}

.faq-answer {
    padding: 1rem 1rem 3rem 1rem; /* Add padding for spacing */
    color: #4a5568; /* Gray text for answer */
    line-height: 1.6; /* Improve readability */
    background-color: #f9f9f9; /* Light gray background for answer */
    border-top: 1px solid #e0e0e0; /* Separator line */
    overflow: hidden; /* Ensure content doesn't overflow */
    transition: all 0.3s ease; /* Smooth transition for height changes */
}

.course-banner {
    width: 100%;
    max-height: 400px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 2rem;
}

/* Course Header */
.course-header {
    text-align: center;
    margin-bottom: 2rem;
}

.course-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.course-meta {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    font-size: 1.1rem;
    color: #666;
}



.enroll-button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: #07a169;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none; /* Remove underline from Link */
    margin: 1.5rem 0;
}

.enroll-button:hover {
    background-color: #055c3d;
}